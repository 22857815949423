$(function(){
	/**
	 * 各種カルーセル
	 * TOPページとサービス詳細ページで使用
	 */
	$('.js-column_carousel').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: true
	});
	$('.js-service_carousel').slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		variableWidth: true
	});
	$('.js-voice_carousel').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: true
	});
})